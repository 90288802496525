export default class SkipHeader {
    constructor() {
        this.init();
    }

    init() {
        this.skipHeaderAnime();
    }

    // 同階層ページ遷移ではヘッダーのアニメーションオフ
    skipHeaderAnime() {
        const url = window.location.href;
        const referrer = document.referrer;

        if (
            this.containsKeywordPair(url, referrer, 'character') ||
            this.containsKeywordPair(url, referrer, 'news') ||
            this.containsKeywordPair(url, referrer, 'story') ||
            this.containsKeywordPair(url, referrer, 'music')
        ) {
            document.body.classList.add('-skipheader');
        }
    }

    containsKeywordPair(url, referrer, keyword) {
        return url.indexOf(keyword) !== -1 && referrer.indexOf(keyword) !== -1;
    }
}
