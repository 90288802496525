import { screenHeight } from './screenheight.es6';
import { gnav } from './gnav.es6';
import Ready from './ready.es6';
import Modal from './modal.es6';
import Movie from './movie.es6';
import Scroll from './scroll.es6';
import Accordion from './accordion.es6';
import SkipHeader from './skip_header.es6';

export default class Main {
    constructor() {
        const ready = new Ready();
        const modal = new Modal();
        const movie = new Movie();
        const scroll = new Scroll();
        const accordion = new Accordion();
        const skipheader = new SkipHeader();

        this.init();
    }

    init() {
        screenHeight();
        gnav();
    }
}

const main = new Main();