import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(SplitText);
export default class Scroll {
    constructor() {
        this.init();
    }

    init() {
        this.set();
        this.smoothScroll();
        this.SplitText();
    }

    set() {
        const revealElm = [...document.querySelectorAll('.js-scrollreveal')];

        revealElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: 'top 80%',
                onEnter: (self) => {
                    el.classList.add('-reveal');

                    if (el.classList.contains('js-split-text')) {
                        //this.SplitText(el);
                    }
                },
            });
        });
    }

    smoothScroll() {
        const triggers = [...document.querySelectorAll('.js-smoothscroll')];

        triggers.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                const hash = el.getAttribute('href');
                const offset = window.innerWidth <= 768 ? window.innerWidth * 0.3 : 200 * (window.innerWidth / 1920);
                gsap.to(window, { duration: 1, scrollTo: { y: hash, offsetY: offset }, ease: 'circ.inOut' });
            });
        });
    }

    SplitText() {
        const targets = gsap.utils.toArray('.js-split-text');
        targets.forEach((target) => {
            let SplitClient = new SplitText(target, { type: 'words,chars' });
            let chars = SplitClient.chars; 

            gsap.from(chars, {
                duration: 2,
                opacity: 0,
                ease: 'circ.inOut',
                stagger: 0.005,
                scrollTrigger: {
                    trigger: target,
                    //markers: true,
                    start: 'top 90%',
                    end: 'bottom center',
                    //scrub: true,
                },
            });
        });
    }
}
