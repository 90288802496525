export default class Movie {
    constructor() {
        this.init();
    }

    init() {
        this.YOUTUBE_URL = '//www.youtube.com/embed/';
        this.YOUTUBE_OPTION = '?playsinline=1';

        this.modal = document.querySelector('.js-moviemodal');

        if (this.modal) {
            this.movie_target = document.querySelector('.moviemodal__iframe__target');

            this.ui();
        }
    }

    ui() {
        [...document.querySelectorAll('.js-moviemodal_open')].forEach(($open) => {
            $open.addEventListener('click', () => {
                const id = $open.getAttribute('data-videoID');
                this.show(id);
                document.body.classList.add('-inmodal');
            });
        });

        this.modal.querySelector('.js-modal_close').addEventListener('click', () => {
            this.hide();

            const activeModal = document.querySelector('.js-modal.-active');
            if (!activeModal) {
                document.body.classList.remove('-inmodal');
            }
        });
    }

    show(youtubeId) {
        const tag = `<iframe src="${
            this.YOUTUBE_URL + youtubeId + this.YOUTUBE_OPTION
        }" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        this.movie_target.innerHTML = tag;
        this.modal.classList.add('-active');
    }

    hide() {
        this.movie_target.innerHTML = '';
        this.modal.classList.remove('-active');
    }
}
