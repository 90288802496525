export default class Modal {
    constructor() {
        this.init();
    }

    init() {
        this.eventBind();
    }

    eventBind() {
        const openbuttons = document.querySelectorAll('.js-modal_open');
        const closebuttons = document.querySelectorAll('.js-modal_close');
        const modals = document.querySelectorAll('.js-modal');

        [...openbuttons].forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                const modalID = el.getAttribute('data-modalID');
                const tgtmodal = document.querySelector(`.js-modal[data-modalID=${modalID}]`);
                tgtmodal && tgtmodal.classList.add('-active');
                document.body.classList.add("-inmodal");
            });
        });

        [...closebuttons].forEach((el) => {
            el.addEventListener('click', () => {
                //[...modals].forEach((el) => el.classList.remove('-active'));
                el.closest('.js-modal').classList.remove('-active');
                document.body.classList.remove("-inmodal");

                const activeModal = document.querySelector('.js-modal.-active');
                if (!activeModal) {
                    document.body.classList.remove('-inmodal');
                }
            });
        });
    }
}
