export default class Accordion {
    constructor() {
        this.init();
    }

    init() {
        this.eventBind();
    }

    resetEvent(reset) {
        this.eventBind(reset);
    }

    eventBind() {
        //アコーディオン
        const triggers = document.querySelectorAll('.js-acc_trigger');

        triggers.forEach((el) => {
            const parent = el.closest('.js-acc');
            const wrapper = el.nextElementSibling;
            const height = wrapper.querySelector('.js-acc_cont').offsetHeight;

            //最初から開いておく
            if (el.classList.contains('-active')) {
                wrapper.style.height = getComputedStyle(wrapper).getPropertyValue('height') === '0px' ? `${height}px` : '0px';
            }

            el.addEventListener('click', () => {
                el.classList.toggle('-active');

                if (getComputedStyle(wrapper).getPropertyValue('height') === '0px') {
                    wrapper.style.height = `${height}px`;

                    if (parent) {
                        parent.style.height = `${parent.offsetHeight + height}px`;
                    }
                } else {
                    wrapper.style.height = '0px';

                    if (parent) {
                        parent.style.height = `${parent.offsetHeight - height}px`;
                    }
                }
            });
        });
    }
}
